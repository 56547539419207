.message-col {
    color: 'red';
    white-space: nowrap;       /* 防止文本换行 */
    overflow: hidden;          /* 隐藏超出部分 */
    text-overflow: ellipsis;   /* 超出部分显示为省略号 */
    width: 100px;              /* 设置一个固定的宽度 */
}

.aiModify {
    height: 91vh;
    overflow: hidden;
}