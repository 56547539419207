.gutter-example {
    // height: calc(100%) !important;

    .ant-row {
        height: 100%;
        .ant-col {
            height: 100%;
            .ant-card {
                height: 100%;
                .ant-card-body {
                    height: 100%;
                }
            }
        }
    }
}
.details-main {
    height: 91vh;
    overflow: hidden;
}
.list-box {
    height: 82%;
    overflow-y: scroll;
    margin-bottom: 10px;
    // padding-bottom: 100px;
}
.list {
    // height: calc(100vh);
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;

    .img-item {
        margin-bottom: 30px;
        height: 170px;
        // background-color: blue;
        
        // padding: 20px;
        text-align: center;
        .item-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .item-phone {
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
        .item-time {
            font-size: 13px;
        }
        img {
            width: 100%;
            height: 100%;
            border: 1px solid #afafaf;
        }
    }
}